import React from 'react';

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { itemsPerPage, totalItems, paginate, currentPage } = this.props;
    const itemsNumber = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      itemsNumber.push(i);
    }

    return (
      <div>
        <nav>
          <ul className='pagination'>
            {itemsNumber.map((item) =>
              currentPage === item ? (
                <li key={item} className='page-item'>
                  <a
                    className='page-link'
                    style={{
                      background: '#7C6262',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => paginate(item)}
                  >
                    {item}
                  </a>
                </li>
              ) : (
                <li key={item} className='page-item'>
                  <a
                    className='page-link'
                    style={{
                      backgroundColor: 'white',
                      color: '#7C6262',
                      cursor: 'pointer',
                    }}
                    onClick={() => paginate(item)}
                  >
                    {item}
                  </a>
                </li>
              )
            )}
          </ul>
        </nav>
      </div>
    );
  }
}
