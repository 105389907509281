import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from '../Modal';
import { returnRequest } from '../../utils/api';

import 'react-toastify/dist/ReactToastify.css';

const ReturnModal = ({ cart, authToken, onDone }) => {
  const [reason, setReason] = useState();
  const [itemToReturn, addItemToReturn] = useState([]);
  if (cart === null) {
    return null;
  }
  const submit = [
    {
      title: 'Return',
      handle: async () => {
        await returnRequest(
          cart.cartId,
          {
            reason,
            items: itemToReturn,
          },
          authToken
        );
        onDone();
        toast.success(
          'Your return request has been submited. We will reach out to you to confirm your request soon',
          {
            className: 'bg-primary text-white',
          }
        );
      },
      disabled: !!!reason,
    },
  ];
  return (
    <Modal
      show={cart !== null}
      handleClose={onDone}
      title='Return this order'
      primaryButtons={submit}
      className='return'
    >
      <h3>You are returning this order:</h3>
      <Form.Group>
        <Form.Label>Why would you like to return this order?</Form.Label>
        <Form.Control
          as='textarea'
          name='reason'
          onChange={(e) => setReason(e.target.value)}
          isValid={!!reason}
        />
        {!!!reason && (
          <Form.Control.Feedback type='invalid' tyle={{ display: 'block' }}>
            You must provide a reason for your return
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Modal>
  );
};

export default ReturnModal;
