import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Row, Col, Table } from 'react-bootstrap';

import OrderCard from './OrderCard';
import Loader from '../Loader';
import ReturnModal from './ReturnModal';
import Pagination from './Pagination';

const itemsPerPage = 10;

const OrderList = ({ orders, ordersLoaded, showActive, accessToken }) => {
  const [cartToReturn, setCartToReturn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    orders && orders.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (number) => setCurrentPage(number);

  if (!ordersLoaded) {
    return <Loader msg='Loading orders. Please wait' />;
  }

  if (orders && orders.length < 1) {
    return (
      <>
        <p>You don't have any {showActive ? 'active' : 'completed'} orders</p>
        <Link to='/model' className='btn btn-primary'>
          Start an Order
        </Link>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col className='px-5'>
          <Table className='table-display' style={{ fontSize: "18px" }}>
            <thead>
              <tr>
                <td>Order Number</td>
                <td>Image</td>
                <td>Description</td>
                <td>Price</td>
                <td>Status</td>
                <td>Date Ordered</td>
                <td>Type</td>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  onReturn={(cartToReturn) => setCartToReturn(cartToReturn)}
                  isDelivered={
                    order.status === 'DELIVERY' || order.status === 'SHIPPED'
                  }
                />
              ))}
              <>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  paginate={paginate}
                />
              </>
            </tbody>
          </Table>
        </Col>
      </Row>
      <ReturnModal
        cart={cartToReturn}
        onDone={() => setCartToReturn(null)}
        authToken={accessToken}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userID: state.login.auth && state.login.auth.profile.id,
  accessToken: state.login.auth && state.login.auth.auth.accessToken,
});

export default connect(mapStateToProps)(OrderList);
